<template>
  <div class="w-full max-w-[768px] mx-auto">
    <common-header v-if="showTopbar" />
    <slot />
    <common-bottom-bar v-if="showTopbar && path !== '/tier'" :current-router="path" />
    <profile-modal v-if="showTopbar" :class="['fixed bottom-0 left-0 z-[11] transition-all duration-300',showProfile ? 'translate-y-0' : 'translate-y-full']" />
    <div v-show="showProfile" class="fixed inset-0 z-[10]"></div>
  </div>
</template>
<script setup>
import UserInfo from '~/store/user'
import Store from '~/store/store'

const router = useRouter()
const route = useRoute()
const { isBoost, boosting } = storeToRefs(UserInfo())
const { viewPortPC, showProfile } = storeToRefs(Store())
const showTopbar = ref(false)
const notHeader = ['/', '/onboard', '/checking-account', '/welcome', '/not-data', '/total-gum']
const overflowHidden = ref(['/leaderboard', '/invite', '/shop'])
const path = ref(window.location.pathname)
const gtmId = useRuntimeConfig().public.gtm
useHead({
  script: [
    {
      hid: 'gtm',
      children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], 
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); 
      })(window,document,'script','dataLayer','${gtmId}');`,
      type: 'text/javascript'
    }
  ],
  noscript: [
    {
      children: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`, body: true
    }
  ]
})
watch(router.currentRoute,() => {
  path.value = window.location.pathname
  showTopbar.value = !notHeader.includes(path.value)
  if(path.value === '/home'){
    isBoost.value = boosting.value
  }else{
    isBoost.value = false
  }
  if(overflowHidden.value.includes(path.value)){
    document.body.style.overflow = 'initial'
  }else{
    document.body.style.overflow = 'hidden'
  }
},{ immediate: true })
watch(showProfile, () => {
  if(showProfile.value){
    document.body.style.overflow = 'hidden'
  }else{
    document.body.style.overflow = 'initial'
  }
}, {immediate : true})
onMounted(() => {
  viewPortPC.value = window.innerHeight < 700
  document.addEventListener('wheel',function (event) {
      if (event.ctrlKey) {
        event.preventDefault()
      }
    },
    { passive: false }
  )
  document.addEventListener('keydown', function (event) {
    if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-')) {
      event.preventDefault()
    }
  })
})
</script>
<style>
.nuxt-devtools-panel {
  display: none !important;
}
</style>
