<template>
  <div class="h-full pt-[3rem] flex flex-col justify-start w-full" >
    <div class="w-full h-full p-4 max-w-[768px] mx-auto bg-white-0 shadow-[0px_-4px_10px_0px_rgba(153,170,184,0.40)] rounded-t-[1.5rem] overflow-hidden flex flex-col gap-2">
      <div class="w-full flex justify-between items-center p-2">
        <p class="text-gray-2 text-[0.875rem] font-semibold">Your Account</p>
        <button @click="closeModal()">
          <img src="@/assets/icons/icon_close.svg" class="w-6 h-6">
        </button>
      </div>
      <!--  -->
      <div :class="['shadow-[0px_2px_10px_0px_rgba(25,27,31,0.20)] p-4 rounded-[1rem] flex flex-col gap-4', `bg_card_${bgRank}`]">
        <!--  -->
        <div class="bg-[rgba(0,0,0,0.20)] rounded-[0.5rem] w-full justify-between p-2 flex">
          <div class="flex gap-2 items-center">
            <img :src="userInfo.avatar || avatar" class="w-8 h-8 rounded-[50%]">
            <div class="flex flex-col">
              <p class="text-[0.75rem] text-white-0 font-semibold">{{ userInfo.username || '--' }}</p>
              <p class="text-[0.625rem] text-white-0 font-medium">#{{ userInfo.telegram_id }}</p>
            </div>
          </div>
          <!-- <button class="flex gap-1 items-center" @click="$router.push('/tier'), showProfile = false">
            <img src="@/assets/icons/icon-medal.webp" class="w-4 h-4" >
            <p class="text-[0.75rem] text-white-0 font-medium">{{ dataHome.tier_current }}</p>
            <img src="@/assets/icons/arrow_right_white.svg" class="w-[0.875rem] aspect-square">
          </button> -->
        </div>
        <!--  -->
        <div class="flex flex-col items-center justify-center">
          <p class="text-[1rem] text-white-0 font-medium">Balance</p>
          <div class="flex gap-2 items-center">
            <img src="@/assets/icons/icon_cart_circle.svg" class="w-8 h-8">
            <p class="text-[2rem] font-bold text-white-0">{{ balanceText }}</p>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="flex flex-col gap-[0.125rem]">
        <div v-for="item in profile" :key="item.id" class="w-full flex justify-between items-center p-2">
          <p class="text-gray-1 text-[0.75rem] font-semibold">{{ item.title }}</p>
          <div v-if="item.id !== 0" class="flex gap-1 items-center">
            <p :class="['text-[0.75rem]', item.id === 1 ? 'text-[#FF4343]' : item.id === 2 ? 'text-green-1' : 'text-[#00A3FF]']">
              +{{ item.data }}%
            </p>
            <img :src="item.icon" class="w-4 h-4">
          </div>
          <p v-else class="text-gray-2 text-[0.75rem] font-semibold">{{ item.data }} {{ item.data === 1 ? 'year ago' : 'years ago' }}</p>
        </div>
      </div>
      <!--  -->
      <!-- <common-button type="primary" class="!p-2">
        <template #content>
          <p class="text-base font-bold fon-noto-sans">Buy Boost</p>
        </template>
      </common-button> -->
    </div>
  </div>
</template>
<script setup lang="ts">
import { formatCoin } from '../../utils/format'
import avatar from '@/assets/images/avt-default.png'
import UserInfo from '~/store/user'
import Store from '~/store/store'
import rocket from '@/assets/icons/rocket.webp'
import vipBoost from '@/assets/icons/icon-vip-boost.svg'
import people from '@/assets/icons/people_active.svg'
const { userInfo, dataHome, boosting, booster, balance } = storeToRefs(UserInfo())
const { showProfile } = storeToRefs(Store())
const profile = ref<any>([])
const closeModal = () => {
  showProfile.value = false
}
const bgRank = computed(() => {
  return dataHome.value.tier_current === 'GUM Tycoon' ? 'tycoon' : dataHome.value?.tier_current.toLocaleLowerCase() ?? 'novice'
})
const balanceText = computed(() => {
  return formatCoin(balance.value)
})
watch(showProfile,() => {
  if(showProfile.value){
    profile.value = [
      {
        title: 'Joined',
        data: userInfo.value.tlg_age,
        id: 0
      },
      {
        title: 'Active Boost',
        data: boosting.value ? booster.value - 100 : 0 ,
        id: 1,
        icon: rocket
      },
      {
        title: 'Friend Boost',
        data: dataHome.value.friend_boost - 100,
        id: 2,
        icon: people
      },
      {
        title: 'Mining Boost',
        data: dataHome.value.vip_boost - 100,
        id: 3,
        icon: vipBoost
      },
    ]
  }
})
</script>
<style scoped lang="scss">
.bg_card_{
  &novice{
    background: linear-gradient(110deg, #002827 0%, #66E0D9 100%);
    border: 1px solid #CCFDFA;
  }
  &trader{
    background: linear-gradient(110deg, #001528 0%, #6AA8E0 100%);
    border: 1px solid #6AA8E0;
  }
  &merchant{
    background: linear-gradient(110deg, #000 0%, #EDA4FF 100%);
    border: 1px solid #C45FC6;
  }
  &shopkeeper{
    background: linear-gradient(110deg, #380000 0%, #FF7979 100%);
    border: 1px solid #BA5050;
  }
  &tycoon{
    background: linear-gradient(90deg, #483800 0%, #EEE8AB 100%);
    border: 1px solid #EEE8AB;
  }
}
</style>